import React from "react";
import Atom from "../atoms/Atoms";
import { GoBack } from "../atoms/components/GoBack";
import { MediaCard } from "../atoms/MediaCard";

class Work extends React.Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        <Atom.Container style={{ padding: "5px" }}>
          <div
            style={{ padding: 10, cursor: "pointer" }}
            onClick={(e: any) => {
              e.preventDefault();
              window.location.href = "/";
            }}
          >
            <Atom.Card style={{ padding: "2rem", width: "Auto" }}>
              <h1>Things made:</h1>
            </Atom.Card>
          </div>
          <Atom.Grid
            container
            item
            xs={12}
            spacing={3}
            justify="center"
            alignItems="stretch"
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              paddingLeft: "1.6rem",
              maxHeight: "calc(90vh - 45px)",
              width: "100vw",
            }}
          >
            <Atom.Grid item>
              <MediaCard
                id="github"
                title="GitHub"
                description="github.com/aarnas"
                clickLink={"https://github.com/aarnas"}
                lottiePath={
                  "https://assets2.lottiefiles.com/packages/lf20_6HFXXE.json"
                }
                lottieSpeed={2}
                loop
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="Assistant"
                title="Assistant"
                description="Ask anything about me"
                clickLink={"https://chat.me365.xyz"}
                lottiePath={
                  "https://assets5.lottiefiles.com/packages/lf20_vjxfqggs.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="autoHubs"
                title="Automation Hubs"
                description="Create and manage clicking / typing events"
                clickLink={"https://github.com/aarnas/more-than-clicker"}
                lottiePath={
                  "https://assets10.lottiefiles.com/packages/lf20_uui8d4hv.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="picoCtrl"
                title="Pico Ctrl"
                description="An app which lets you control Raspberry Pi Pico W with easy no code set-up"
                clickLink={"https://pico.me365.xyz/"}
                lottiePath={
                  "https://assets9.lottiefiles.com/packages/lf20_amz6hpyk.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="generator"
                title="AI generator"
                description="AI caption generator"
                clickLink={
                  "https://play.google.com/store/apps/details?id=com.me365xyz.genbyai"
                }
                lottiePath={
                  "https://assets2.lottiefiles.com/packages/lf20_yagrzsqp.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            {/* <Atom.Grid item>
              <MediaCard
                id="master"
                title="AI Web pages rating"
                description="Master final of chrome extension evaluationg web pages visited"
                clickLink={"https://github.com/aarnas/Master-Thesis"}
                lottiePath={
                  "https://assets9.lottiefiles.com/packages/lf20_l5qvxwtf.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid> */}

            <Atom.Grid item>
              <MediaCard
                id="img-in-img"
                title="Image-in-Img Search"
                description="npm package to find image in image"
                clickLink={"https://www.npmjs.com/package/image-in-image"}
                lottiePath={
                  "https://assets4.lottiefiles.com/packages/lf20_urbk83vw.json"
                }
                lottieSpeed={2}
                loop
                hoverPlay
              />
            </Atom.Grid>

            {/* <Atom.Grid item>
              <MediaCard
                id="qr"
                title="Excuse Code"
                description="QR scan to contact solution"
                clickLink={"https://excusecode.com/"}
                lottiePath={
                  "https://assets3.lottiefiles.com/private_files/lf30_m2aabgw6.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid> */}

            <Atom.Grid item>
              <MediaCard
                id="notify"
                title="Web crawler notifier"
                description="Notifies about new cars added"
                clickLink={"https://github.com/aarnas/Longo-Web-Push"}
                lottiePath={
                  "https://assets6.lottiefiles.com/packages/lf20_fdo4fhy6.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="jsonObfuscator"
                title="JSON obfuscator"
                description="Tool which replaces all the strings in a file with unicode escape sequences"
                clickLink={"https://github.com/aarnas/obfuscate"}
                lottiePath={
                  "https://assets10.lottiefiles.com/private_files/lf30_esg1l8r1.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="jp"
                title="HairRecipe.jp"
                description="Website redesign nearly from scratch"
                clickLink={"https://hairrecipe.jp/"}
                lottiePath={
                  "https://assets8.lottiefiles.com/packages/lf20_77C6e0tonx.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="stackoverflow"
                title="Stack Overflow"
                description="/users/13256340/aarnas"
                clickLink={"https://stackoverflow.com/users/13256340/aarnas"}
                lottiePath={
                  "https://assets2.lottiefiles.com/datafiles/BIlBfRHd5ivAbrZre1f5IS78LGpgM0yRLx5JSZZx/stackover flow.json"
                }
                lottieSpeed={2}
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="blogger"
                title="BLOG365"
                description="blog that contains specific articles that is used as notes"
                clickLink={"https://blog.me365.xyz/"}
                lottiePath={
                  "https://assets3.lottiefiles.com/private_files/lf30_BgGAtj.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="multiPrimes"
                title="Multithreading Primes"
                description="Interactive tool that calculates primes in multiple threads"
                clickLink={"https://github.com/aarnas/multithreading-primes"}
                lottiePath={
                  "https://assets6.lottiefiles.com/packages/lf20_2ttgaxh0.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            {/* <Atom.Grid item>
              <MediaCard
                id="by"
                title="Benefits You"
                description="Health and wellbeing benefits app"
                clickLink={"https://benefitsyou.app/"}
                lottiePath={"https://assets9.lottiefiles.com/packages/lf20_xgdvjjxc.json"}
                bounce
                hoverPlay
              />
            </Atom.Grid> */}

            <Atom.Grid item>
              <MediaCard
                id="mac"
                title="MAC OS"
                description="mac os simulator with presentative content"
                clickLink={"https://macos.me365.xyz"}
                lottiePath={
                  "https://assets5.lottiefiles.com/packages/lf20_m0Kphd.json"
                }
                autoplay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="MFC"
                title="Member Face Control"
                description="AI face matching with document data"
                clickLink={
                  "https://play.google.com/store/apps/details?id=lt.softemia.android.eventsid"
                }
                lottiePath={
                  "https://assets10.lottiefiles.com/datafiles/IEoWf6Kv3cm5nVAmDnH3uZHAisL7G5uFi0IM2UlC/fingerprint_lottie/fingerprint_lottie.json"
                }
                loop
                hoverPlay
              />
            </Atom.Grid>

            {/* <Atom.Grid item>
              <MediaCard
                id="cliches"
                title="Cliches.app"
                description="App for creating and playing cliches!"
                clickLink={"https://cliches.app/"}
                lottiePath={
                  "https://assets7.lottiefiles.com/packages/lf20_cnkfzsxq.json"
                }
                loop
                hoverPlay
              />
            </Atom.Grid> */}

            <Atom.Grid item>
              <MediaCard
                id="migracija"
                title="Operator program"
                description="Program to control biometric data kiosk"
                clickLink={
                  "https://www.migracija.lt/lt/-/migracijos-departamento-klientai-sostin%C4%97je-pasitinkami-naujose-patalpose?redirect=%2Flt%2Fnaujienos%3Fp_p_id%3Dcom_liferay_asset_publisher_web_portlet_AssetPublisherPortlet_INSTANCE_aqpVaw7y23kR%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26_com_liferay_asset_publisher_web_portlet_AssetPublisherPortlet_INSTANCE_aqpVaw7y23kR_delta%3D12%26p_r_p_resetCur%3Dfalse%26_com_liferay_asset_publisher_web_portlet_AssetPublisherPortlet_INSTANCE_aqpVaw7y23kR_cur%3D3"
                }
                lottiePath={
                  "https://assets2.lottiefiles.com/packages/lf20_frJ5A7.json"
                }
                loop
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="shortLink"
                title="ShortLink"
                description="Short link maker with custom text"
                clickLink={"https://getat.it/"}
                lottiePath={
                  "https://assets1.lottiefiles.com/packages/lf20_2jD6zd.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="mirrors"
                title="Mirrors"
                description="Mirrors, reflecions and interactivity"
                clickLink={"https://mirror.me365.xyz/"}
                lottiePath={
                  "https://assets3.lottiefiles.com/private_files/lf30_1cjTUM.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="hoverCert"
                title="Hover for certificate"
                description="Hover on text effect that reveals certificate"
                lottiePath={
                  "https://assets8.lottiefiles.com/private_files/lf30_N6zXPV.json"
                }
                clickLink={"/cert"}
                bounce
                hoverPlay
              />
            </Atom.Grid>

            {/* <Atom.Grid item>
              <MediaCard
                id="threeGalery"
                title="3D Galery"
                description="3D .png slideshow throught scroll and clicks"
                lottiePath={
                  "https://assets9.lottiefiles.com/packages/lf20_PREjqN.json"
                }
                clickLink={"/3dgalery"}
                bounce
                hoverPlay
              />
            </Atom.Grid> */}

            {/* <Atom.Grid item>
              <MediaCard
                id="webglcube"
                title="WebGL cube"
                description="Cube that contains 3 black .png images"
                lottiePath={
                  "https://assets4.lottiefiles.com/datafiles/aErzof776pFw1sk/data.json"
                }
                clickLink={"https://gintaute.me365.xyz/"}
                loop
                hoverPlay
              />
            </Atom.Grid> */}

            {/* <Atom.Grid item>
              <MediaCard
                id="threeJS"
                title="ThreeJS sky"
                description="ThreeJS playground"
                clickLink={"/scene"}
                lottiePath={
                  "https://assets7.lottiefiles.com/packages/lf20_x62chJ.json"
                }
                loop
                hoverPlay
              />
            </Atom.Grid> */}

            <Atom.Grid item>
              <MediaCard
                id="unitykids"
                title="UnityKids"
                description="Games created by children of the academy"
                lottiePath={
                  "https://assets2.lottiefiles.com/packages/lf20_ctiEUT.json"
                }
                clickLink={"https://unity.me365.xyz/"}
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="car"
                title=".NET car"
                description="Program that illustrates prototype rotation"
                lottiePath={
                  "https://assets10.lottiefiles.com/datafiles/HN7OcWNnoqje6iXIiZdWzKxvLIbfeCGTmvXmEm1h/data.json"
                }
                clickLink={"/car"}
                loop
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="issauga"
                title="ISSAUGA"
                description="Bachelor - Security learning platform for lecturers"
                lottiePath={
                  "https://assets8.lottiefiles.com/packages/lf20_wEt2nn.json"
                }
                clickLink={"https://issauga.me365.xyz"}
                loop
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="soundcloud"
                title="SoundCloud"
                description="Creating tracks independently. Using: loops, vocals, short clipping.. Program: FL Studio"
                lottiePath={
                  "https://assets5.lottiefiles.com/packages/lf20_XP4tPo.json"
                }
                clickLink={"https://soundcloud.com/soundss-off-godds"}
                loop
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="old"
                title="Old site"
                description="Older website to compare the changes"
                lottiePath={
                  "https://assets9.lottiefiles.com/temp/lf20_q9r5yY.json"
                }
                clickLink={"https://old.me365.xyz"}
                loop
                hoverPlay
              />
            </Atom.Grid>
          </Atom.Grid>
        </Atom.Container>
        <GoBack />
      </div>
    );
  }
}

export default Work;
