import React from "react";
import { createTheme } from "@material-ui/core/styles";

export const OPACITY = 0.7;
export const DARK_OPACITY = 0.5;
export const BLUR = 5; // px

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2c98f0",
      light: "rgba(255,255,255,0.7)",
    },
  },
});
