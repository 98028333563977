import React from "react";
import "./App.css";
// @ts-ignore
import { BrowserRouter, Route, Switch } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Background from "./views/Background";
import StartScreen from "./views/StartScreen";
import Skills from "./views/Skills";
import Work from "./views/Work";
import Car from "./views/Car";
import Contact from "./views/Contact";
import Cert from "./views/Cert";

import NotFound from "./views/NotFound";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route exact path={["/", "/work", "/car", "/contact"]}>
            <Background />
            <Route exact path="/">
              <StartScreen />
            </Route>
            <Route exact path="/work">
              <Work />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/car">
              <Car />
            </Route>
          </Route>
          <Route exact path="/skills">
            <Background backgroundUrl="https://assets8.lottiefiles.com/private_files/lf30_3zjr9l1k.json" />
            <Skills />
          </Route>
          <Route exact path="/cert">
            <Cert />
          </Route>
          <Route any>
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
