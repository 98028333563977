import React, { PureComponent } from "react";
import planck, { Vec2 } from "planck-js";
import Box from "./Box";
import Mouse from "./Mouse";

const R2D = 180 / Math.PI; // Multiply to convert radians to degrees
const PI2 = Math.PI * 2; // 360 degrees in radians
const worldScale = 40;

class Boxes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      // blocks: this.props.players
      blocks: [
        { id: "ai", name: "AI", rating: 1 },
        { id: "iot", name: "IoT", rating: 5 },
        { id: "node", name: "NodeJS", rating: 2 },
        { id: "react", name: "React", rating: 3 },
        { id: "wp", name: "WordPress", rating: 4 },
        { id: "docker", name: "Docker", rating: 5 },
        { id: "JS", name: "JavaScript", rating: 5 },
        { id: "sql", name: "SQL", rating: 5 },
        { id: "python", name: "Python", rating: 5 },
        { id: "mongo", name: "MongoDB", rating: 5 },
        { id: "noSQL", name: "noSQL", rating: 5 },
        { id: "JSON", name: "JSON", rating: 5 },
        { id: "XML", name: "XML", rating: 5 },
        { id: "WebGL", name: "WebGL", rating: 5 },
        { id: "cyberForensics", name: "Forensics", rating: 1 },
        { id: "reversEngineering", name: "Engineering", rating: 1 },
        { id: "pentesting", name: "PENTEST", rating: 1 },
        { id: "nfc", name: "NFC", rating: 5 },
        { id: "JWT", name: "JWT", rating: 5 },
        { id: "UML", name: "UML", rating: 5 },
        { id: "Java", name: "Java", rating: 5 },
        { id: "linux", name: "Linux", rating: 5 },
        { id: "ios", name: "iOS", rating: 5 },
        { id: "TSX", name: "TSX", rating: 5 },
        { id: "Android", name: "Android", rating: 5 },
        { id: "UI", name: "UI", rating: 5 },
        { id: "UX", name: "UX", rating: 5 },
        { id: "ExpressjS", name: "ExpressJS", rating: 5 },
        { id: "cook", name: "Cooking", rating: 3 },
        { id: 'git', name: 'Git', rating: 5 },
        { id: 'jira', name: 'Jira', rating: 5 },
        { id: 'tensorflow', name: 'Tensorflow', rating: 5 },
        { id: 'machineLearning', name: 'ML', rating: 5 },
        { id: 'neuralNetworks', name: 'NN', rating: 5 }
      ]
    };

    this.totalBoxes = 0;
    this.generator = null;
    this.canvasWidth = null;
    this.canvasHeight = null;

    this.update = this.update.bind(this);
  }

  componentDidMount() {
    this.canvasWidth = window.innerWidth;
    this.canvasHeight = window.innerHeight;

    this.world = planck.World(Vec2(0, 20));
    this.world.createBody().createFixture(planck.Edge(Vec2(-this.canvasWidth / worldScale, this.canvasHeight / worldScale), Vec2(this.canvasWidth / worldScale, this.canvasHeight / worldScale)), 0.0);
    this.world.createBody().createFixture(planck.Edge(Vec2(0, -10), Vec2(0, this.canvasHeight / worldScale)), 0.0);
    this.world.createBody().createFixture(planck.Edge(Vec2(this.canvasWidth / worldScale, -10), Vec2(this.canvasWidth / worldScale, this.canvasHeight / worldScale)), 0.0);
    this.world.createBody().createFixture(planck.Edge(Vec2(0, -10), Vec2(this.canvasWidth / worldScale, -10)), 0.0);

    this.mouse = new Mouse();

    this.animationFrame = requestAnimationFrame(this.update);
    this.mouse.init(this.world, worldScale);

    this.generator = setInterval(() => {
      if (this.totalBoxes < this.state.blocks.length) {
        let multiplier = 120;
        if (this.canvasWidth < 767) multiplier = 80;

        // const x = Math.floor(Math.random() * this.canvasWidth) - 100;
        const x = Math.floor(Math.random() * this.canvasWidth);
        const w = Math.floor((Math.random() * (1 * multiplier)) + this.canvasWidth / 12);
        const h = Math.floor((Math.random() * (1 * multiplier)) + this.canvasHeight / 18);
        const body = this.world.createDynamicBody(Vec2(x / worldScale, -5));
        body.createFixture(planck.Box(w / 2 / worldScale, h / 2 / worldScale), {
          density: 5,
          friction: 1,
          restitution: 0.5
        });
        body.m_userData = { i: this.totalBoxes, w: w / 2, h: h / 2 };

        const blocks = this.state.blocks.splice(0);
        blocks[this.totalBoxes].w = w;
        blocks[this.totalBoxes].h = h;
        this.setState({ blocks });

        this.totalBoxes += 1;
      } else {
        clearInterval(this.generator);
        this.generator = null;
      }
    }, 300);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationFrame);
    this.mouse.destroy();
  }

  update() {
    // in each frame call world.step(timeStep) with fixed timeStep
    this.world.step(1 / 60, 10, 10);

    const blocks = this.state.blocks.slice(0);

    // iterate over bodies and fixtures
    for (let body = this.world.getBodyList(); body; body = body.getNext()) {
      if (body.m_userData) {
        const transform = body.getTransform();

        blocks[body.m_userData.i].x = Math.floor(transform.p.x * worldScale) - body.m_userData.w;
        blocks[body.m_userData.i].y = Math.floor(transform.p.y * worldScale) - body.m_userData.h;
        blocks[body.m_userData.i].r = Math.round(((body.getAngle() + PI2) % PI2) * R2D * 100) / 100;
      }
    }

    this.setState({ blocks });

    this.mouse.update();

    this.animationFrame = requestAnimationFrame(this.update);
  }

  render() {
    const { blocks } = this.state;

    return (
      <div className="boxes">
        {blocks.map((skill, i) => (
          <div>
            <Box
              id={skill.name}
              name={skill.name}
              x={skill.x}
              y={skill.y}
              r={skill.r}
              width={skill.w}
              height={skill.h}
              key={skill.name}
            />
            <style jsx>{`
          .boxes {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            user-select: none;
          }
        `}</style>
          </div>
        ))}
      </div>
    );
  }
}

export default Boxes;
