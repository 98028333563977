import React from "react"
import Cursor from '../atoms/HoverImage/cursor';
import {preloader} from '../atoms/HoverImage/preloader';
// @ts-ignore
import LocomotiveScroll from 'locomotive-scroll';
import Menu from '../atoms/HoverImage/menu';

import './Cert.css';

interface Props {
  backgroundUrl?: string,
}

class Cert extends React.Component<Props>{
  componentDidMount(){
    // menu (<nav> element)
    const menuEl = document.querySelector('.menu');

    // preload the images set as data attrs in the menu items
    preloader('.menu__item').then(() => {
    // initialize the smooth scroll
    const scroll = new LocomotiveScroll({el: menuEl, smooth: true});

    // initialize custom cursor
    const cursor = new Cursor(document.querySelector('.cursor'));

    // initialize menu
    new Menu(menuEl);
  });
  }
  render(){
    return(
      <body className="loading">
	    <main>
      <nav className="menu" >
			<a id="vertical" className="menu__item" data-img="hoverImages/teltonika.jpg">
				<span className="menu__item-text"><span className="menu__item-textinner">Teltonika</span></span>
				<span className="menu__item-sub">.NET C#</span>
			</a>
			<a className="menu__item" data-img="hoverImages/REACT-fullstack.jpg">
				<span className="menu__item-text"><span className="menu__item-textinner">REACT-fullstack</span></span>
				<span className="menu__item-sub">Udemy</span>
			</a>
			<a className="menu__item" data-img="hoverImages/JS_ADVANCED.jpg">
				<span className="menu__item-text"><span className="menu__item-textinner">JS Advanced</span></span>
				<span className="menu__item-sub">Udemy</span>
			</a>
			<a className="menu__item" data-img="hoverImages/SEO.jpg">
				<span className="menu__item-text"><span className="menu__item-textinner">SEO</span></span>
				<span className="menu__item-sub">Udemy</span>
			</a>
			<a className="menu__item" data-img="hoverImages/KALI_ETHICAL.jpg">
				<span className="menu__item-text"><span className="menu__item-textinner">Kali Linux Ethical</span></span>
				<span className="menu__item-sub">Udemy</span>
			</a>
		</nav>
    </main>
    <svg className="cursor" width="80" height="80" viewBox="80">
		<circle className="cursor__inner" cx="40" cy="40" r="20"/>
	  </svg>
    </body>
    )
  }
}

export default Cert