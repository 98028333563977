import React from "react";
import Atom from "../atoms/Atoms";
import ReactPlayer from 'react-player';

interface Props {
  backgroundUrl?: string;
}

class Car extends React.Component<Props> {
  render() {
    return (
      <div style={{ height:'100vh', width: '100%', display: "flex", justifyContent: "center", textAlign:'center', alignItems:"center"}}>
          <Atom.Container style={{ zIndex: 1 }}>
              <Atom.Card style={{ padding: "2rem", maxHeight: '90vh', display: "flex", justifyContent: "center", textAlign:'center'}}>
                <h2>Check this video of rotation viewer prototype in .NET</h2>
                <ReactPlayer width='100vw' height='100vh' url='/video/Media1.mp4' loop playing muted/>
              </Atom.Card>
          </Atom.Container>
      </div>
    );
  }
}

export default Car;
