import React from "react"
import { Lottie } from "../atoms/Lottie"

interface Props {
  backgroundUrl?: string,
}

class NotFound extends React.Component<Props>{
  render(){
    return(
      <Lottie autoplay loop id={"gradient-back"} path={"https://assets9.lottiefiles.com/packages/lf20_4DPcyu.json"} style={{position:'fixed', top:0, width:'100%', height:'100%', zIndex:-1}}/>
    )
  }
}

export default NotFound