import React from "react";
import Atom from "../atoms/Atoms";
import { GoBack } from "../atoms/components/GoBack";
import { MediaCard } from "../atoms/MediaCard";

class Contact extends React.Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        <Atom.Container style={{ padding: "5px" }}>
          <div
            style={{ padding: 10, cursor: "pointer" }}
            onClick={(e: any) => {
              e.preventDefault();
              window.location.href = "/";
            }}
          >
            <Atom.Card style={{ padding: "2rem", width: "Auto" }}>
              <h1>Contacts:</h1>
            </Atom.Card>
          </div>
          <Atom.Grid
            container
            item
            xs={12}
            spacing={3}
            justify="center"
            alignItems="stretch"
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              paddingLeft: "1.6rem",
              maxHeight: "calc(90vh - 35px)",
              width: "100vw",
            }}
          >
            <Atom.Grid item>
              <MediaCard
                id="linkedIn"
                title="LinkedIn"
                description="in/arnas-andriuskevicius"
                clickLink={"https://www.linkedin.com/in/arnas-andriuskevicius"}
                lottiePath={
                  "https://assets6.lottiefiles.com/packages/lf20_0Cm1Y2.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="email"
                title="Email"
                description="arnas@me365.xyz"
                clickLink={"mailto:arnas@me365.xyz"}
                lottiePath={
                  "https://assets7.lottiefiles.com/packages/lf20_se3w0ukg.json"
                }
                loop
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="Assistant"
                title="Assistant"
                description="Ask anything about me"
                clickLink={"https://chat.me365.xyz"}
                lottiePath={
                  "https://assets5.lottiefiles.com/packages/lf20_vjxfqggs.json"
                }
                bounce
                hoverPlay
              />
            </Atom.Grid>

            <Atom.Grid item>
              <MediaCard
                id="cv"
                title="CV Resume"
                description="Arnas-A.pdf"
                clickLink={"https://me365.xyz/Arnas-A.pdf"}
                lottiePath={
                  "https://assets5.lottiefiles.com/private_files/lf30_3afizop1.json"
                }
                // lottieSpeed={2}
                loop
                hoverPlay
              />
            </Atom.Grid>
          </Atom.Grid>
          <GoBack />
        </Atom.Container>
      </div>
    );
  }
}

export default Contact;
