import React from "react";
import * as Mui from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
export const OPACITY = 0.7;
export const DARK_OPACITY = 0.5;
export const BLUR = 20; // px

interface BlurProps {
  transparent?: boolean;
  className?: string;
}

export const Blur = (props: BlurProps) => {
  const style = props.transparent
    ? {
        backgroundColor: `rgba(0,0,0,${OPACITY})`,
        backdropFilter: `blur(${BLUR}px)`,
      }
    : {};
  return <div style={style} className={props.className} />;
};

export const Hidden = (props: { children: JSX.Element }) => (
  <div style={{ visibility: "hidden" }}>{props.children}</div>
);

export const Container = (
  props: React.ComponentProps<typeof Mui.Container>
) => {
  props = { ...props, maxWidth: props.maxWidth };
  return <Mui.Container {...props}>{props.children}</Mui.Container>;
};

// /////////////////////
// Typography T
// /////////////////////

interface TypographyProps {
  // prettier-ignore
  variant: 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  //| 'body1'
  //| 'body2'
  //| 'caption'
  //| 'button'
  //| 'overline'
  //| 'srOnly'
  //| 'inherit'
  display?: "initial" | "block" | "inline";
  align?: "inherit" | "left" | "center" | "right" | "justify";
  color?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  text?: string;
  className?: string;
  noWrap?: boolean;
  style?: any;
  onClick?: () => void;
}

export const T = (props: TypographyProps) => {
  const { text, ...other } = props;
  return <Mui.Typography {...other}> {text} </Mui.Typography>;
};

// /////////////////////
// AVATAR
// /////////////////////

export interface AvatarProps {
  size: "small" | "medium" | "large";
  src?: string;
  alt?: string;
  badge?: {
    badgeContent?: string | number;
    max?: number;
  };
  variant?: "circle" | "rounded";
  badgeContent?: string;
  style?: any;
}

const avatarSizes = {
  small: { height: 30, width: 30 },
  medium: { height: 50, width: 50 },
  large: { height: 80, width: 80 },
};

export const Avatar = (props: AvatarProps) => {
  let { size, badge, alt, ...other } = props;
  other = { ...other, style: { ...avatarSizes[size || "medium"] } } as any;
  const avatar = <Mui.Avatar {...other}>{alt}</Mui.Avatar>;
  if (badge) return <Badge {...badge}>{avatar}</Badge>;
  return avatar;
};

export const Badge = Mui.Badge;

// /////////////////////
// BUTTON
// /////////////////////

export const Fab = (props: React.ComponentProps<typeof Mui.Fab>) => {
  if (props.color === "default") {
    props = {
      ...props,
      style: {
        ...props.style,
        backgroundColor: `rgba(255,255,255,${OPACITY})`,
        backdropFilter: `blur(${BLUR}px)`,
      },
    };
  }
  return <Mui.Fab {...props} />;
};

interface ButtonProps {
  color?: "default" | "primary" | "error" | "success";
  variant?: "outlined" | "contained" | "text";
  size?: "small" | "medium" | "large";
  label?: string;
  Icon?: any;
  href?: string;
  onClick?: () => void;
  className?: string;
  style?: any;
}

export const Button = (allProps: ButtonProps) => {
  let { href, ...props } = allProps;
  // @ts-ignore
  props = { ...props, component: Link, to: href };
  props = { ...props, size: props.size || "small" };
  props = { ...props, variant: props.variant || "text" };
  const { label, Icon, color, variant } = props;
  const IconElement = Icon ? <Icon style={{ height: "1.25rem" }} /> : <div />;

  if (variant === "outlined" || variant === "text") {
    props = {
      ...props,
      style: {
        ...props.style,
        backgroundColor: `rgba(255,255,255,${OPACITY})`,
        backdropFilter: `blur(${BLUR}px)`,
      },
    };
  }

  if (variant === "contained" && color === "default") {
    return (
      // @ts-ignore
      <Mui.Button
        {...props}
        //style={{ ...props.style, backgroundColor: theme.palette.primary.main }}
      >
        {IconElement}
        {label}
      </Mui.Button>
    );
  }

  if (color === "error" || color === "success") {
    // const temp_theme = createTempTheme(
    //   color === "error" ? theme.palette.error.main : theme.palette.success.main
    // )

    return (
      //<ThemeProvider theme={temp_theme}>
      <Mui.Button {...props} color="primary">
        {IconElement}
        {label}
      </Mui.Button>
      //</ThemeProvider>
    );
  } else {
    return (
      // @ts-ignore
      <Mui.Button {...props}>
        {IconElement}
        {label}
      </Mui.Button>
    );
  }
};

// /////////////////////
// CHIP
// /////////////////////

interface ChipProps {
  label?: string;
  Icon?: any;
  color?: "default" | "primary" | "secondary";
  outlined?: boolean;
  onClick?: () => void;
  className?: string;
}

export const Chip = (props: ChipProps) => {
  // @ts-ignore
  props = { ...props, variant: props.outlined && "outlined" };
  const { label, Icon } = props;
  if (!label && Icon) {
    return (
      <Mui.Chip
        {...props}
        style={{ width: 32 }}
        icon={
          <Icon style={{ position: "absolute", height: 22, marginLeft: 0 }} />
        }
        clickable
      />
    );
  }
  return (
    <Mui.Chip
      {...props}
      onDelete={Icon ? () => {} : undefined}
      deleteIcon={Icon && <Icon style={{ height: 26 }} />}
      clickable
    />
  );
};

// /////////////////////
// CARD
// /////////////////////

interface CardProps {
  transparent?: boolean;
  bgUrl?: string;
  children?: JSX.Element | JSX.Element[] | "string";
  leftButton?: JSX.Element;
  rightButton?: JSX.Element;
  className?: string;
  style?: any;
}

const cardStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // maxWidth: 512,
    backgroundColor: "rgba(0,0,0,0.5)",
    color: "white",
    borderRadius: "3rem",
  },
}));

export const Card = (props: CardProps) => {
  const classes = cardStyles();
  const bgStyle = props.transparent
    ? {
        backdropFilter: `blur(${BLUR}px)`,
        padding: "3%",
        borderRadius: "2rem",
        borderWidth: 3,
      }
    : undefined;
  const body = (
    <div className="h-100 w-100" style={bgStyle}>
      {props.children}
    </div>
  );
  return (
    <Mui.Card
      className={clsx(classes.root, props.className)}
      style={props.style}
    >
      {props.bgUrl ? (
        <Mui.CardMedia
          style={{ height: "100%" }}
          image={props.bgUrl}
          title="Background"
        >
          {body}
        </Mui.CardMedia>
      ) : (
        body
      )}
      {(props.leftButton || props.rightButton) && (
        <Mui.CardActions disableSpacing>
          {props.leftButton}
          <div className="d-flex flex-grow-1" />
          {props.rightButton}
        </Mui.CardActions>
      )}
    </Mui.Card>
  );
};

// /////////////////////
// DIALOG
// /////////////////////
interface DialogProps {
  title?: string;
  open?: boolean;
  onClose: () => void;
  onAgree?: () => void;
  onDisagree?: () => void;
  disagreeLabel?: string;
  agreeLabel?: string;
  children: JSX.Element | JSX.Element[];
}

export const DialogContent = Mui.DialogContent;

export const Dialog = (props: DialogProps) => {
  const {
    onClose,
    onAgree,
    onDisagree,
    agreeLabel,
    disagreeLabel,
    title,
    ...other
  } = props;

  return (
    // @ts-ignore
    <Mui.Dialog {...other} onClose={onClose}>
      <Mui.DialogTitle>
        <div className="d-flex">
          <Mui.Typography variant="h6">{title}</Mui.Typography>
          <div className="d-flex flex-grow-1" />
          <Hidden>
            <Button color="error" label="CLO" />
          </Hidden>
        </div>
        <Button
          color="error"
          label="CLOSE"
          onClick={onClose}
          style={{ position: "absolute", right: 10, top: 10 }}
        />
      </Mui.DialogTitle>
      {props.children}
      <Mui.DialogActions>
        {onDisagree && (
          <Button
            color="error"
            label={disagreeLabel || "CANCEL"}
            onClick={() => {
              onDisagree();
              onClose();
            }}
          />
        )}
        <div className="d-flex flex-grow-1" />
        {onAgree && (
          <Button
            color="primary"
            label={agreeLabel || "SAVE"}
            onClick={() => {
              onAgree();
              onClose();
            }}
          />
        )}
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

// /////////////////////
// ListItem
// /////////////////////
export const List = Mui.List;

interface ListItemProps {
  avatar?: Omit<AvatarProps, "size">;
  Icon?: any;
  title?: string;
  subtitle?: string;
  endJSX?: JSX.Element;
  onClick?: () => void;
  children?: JSX.Element | JSX.Element[];
}

const listItemStyles = makeStyles((theme) => ({
  root: {
    // "& .MuiListItemText-secondary": {
    //   color: "black",
    // },
  },
}));

export const ListItem = (props: ListItemProps) => {
  const { Icon, avatar, children, onClick } = props;
  const classes = listItemStyles();
  const item = (
    <div style={{ display: "inline-block" }}>
      {children}
      <Mui.ListItem style={{ fontSize: "3rem" }} className={classes.root}>
        {Icon && (
          <Mui.ListItemAvatar>
            <Icon style={{ fontSize: "3rem" }} />
          </Mui.ListItemAvatar>
        )}
        {avatar && (
          <Mui.ListItemAvatar>
            <Avatar size="large" {...avatar} />
          </Mui.ListItemAvatar>
        )}
        {props.title}
        {/* <Mui.ListItemText style={{width:'50'}} primary={props.title} secondary={props.subtitle} />
        {props.endJSX && (
          <Mui.IconButton edge="end">{props.endJSX}</Mui.IconButton>
        )} */}
      </Mui.ListItem>
    </div>
  );
  // prettier-ignore
  return !onClick ? item : <ButtonBase onClick={onClick} className="w-100">{item}</ButtonBase>
};

export const CardContent = Mui.CardContent;
// export const CardActions = Mui.CardActions
export const CardHeader = Mui.CardHeader;
export const CardActionArea = Mui.CardActionArea;

// /////////////////////
// LINEAR PROGRESS
// /////////////////////

interface LinearProgressProps {
  color: "primary" | "secondary";
  sticky?: boolean;
  style?: any;
}

export const LinearProgress = (props: LinearProgressProps) => {
  props = {
    ...props,
    sticky: props.sticky === undefined ? true : props.sticky,
  };
  if (props.sticky) props = { ...props, style: { position: "sticky", top: 0 } };
  return <Mui.LinearProgress {...props} />;
};

export const ButtonBase = Mui.ButtonBase;
export const Grid = Mui.Grid;
export const CardMedia = Mui.CardMedia;
