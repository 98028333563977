import React from "react"
import Atom from "../atoms/Atoms"

interface Props {
  backgroundUrl?: string,
}

class StartScreen extends React.Component<Props>{
  render(){
    return(
      <div style={{width:"100%", height:"100vh"}}>
      <Atom.Container style={{padding:"5px"}}>

        <div style={{padding:10}}>
        <Atom.Card style={{padding:"2rem", width:'Auto'}}>
          <h1>Hi, I am Arnas</h1>
          <h3>Click on messages to find out more.</h3>
        </Atom.Card>
        </div>

        <div style={{cursor:'pointer', padding:10}} onClick={(e: any) => { 
          e.preventDefault();
          window.location.href='/skills';
        }}>
        <Atom.Card style={{padding:"2rem", width:'Auto'}}>
          <h1>About me</h1>
        </Atom.Card>
        </div>

        <div style={{cursor:'pointer', padding:10}} onClick={(e: any) => { 
          e.preventDefault();
          window.location.href='/contact';
        }}>
        <Atom.Card style={{padding:"2rem", width:'Auto'}}>
          <h1>Contacts</h1>
        </Atom.Card>
        </div>

        <div style={{cursor:'pointer', padding:10}} onClick={(e: any) => { 
          e.preventDefault();
          window.location.href='/work';
        }}>
        <Atom.Card style={{padding:"2rem", width:'Auto'}}>
          <h1>Work</h1>
        </Atom.Card>
        </div>
        </Atom.Container>
        </div>
    )
  }
}

export default StartScreen