import React, {useEffect} from "react"
import lottie from "lottie-web";

interface LotProps {
  id?: string,
  source?: any,
  path?: string,
  style?: any,
  loop?: boolean,
  bounce?: boolean,
  autoplay?: boolean,
  aspect?: string,
  hoverPlay?: boolean,
  speed?: number
}

export const Lottie = (props: LotProps) => {
  useEffect(() => {
    var animationSpray = document.getElementById("#spray"+props.id);
    let direction = 1;
    var preLoad = lottie.loadAnimation({
      // @ts-ignore
      container: document.querySelector("#"+props.id || "#react-logo"),
      loop: props.loop || false,
      animationData: props.source || '',
      path: props.path,
      autoplay: props.autoplay || false,
      progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
      hideOnTransparent: true, //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
      renderer: 'svg',
      rendererSettings: { preserveAspectRatio: props.aspect || 'xMidYMid meet'},
    });
    preLoad.setSpeed( props.speed || 0.5 );
    {props.bounce &&
    preLoad.addEventListener('complete', () => {
      direction *= -1;
      // @ts-ignore
      preLoad.setDirection(direction);
      preLoad.play();
    });}
    
    {props.hoverPlay && animationSpray!.addEventListener("mouseenter", fEnter);}
    {props.hoverPlay && animationSpray!.addEventListener("mouseleave", fLeave);}

    function fEnter() {
      preLoad!.play();
    }

    function fLeave() {
      preLoad!.pause();
    }
    
  }, []);

  return (
    <div id={"#spray"+props.id}>
    <div id={props.id || "react-logo"} style={props.style}/>
    </div>
  )
}
