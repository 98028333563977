import React from "react";
import Atom from "../atoms/Atoms";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Lottie } from "../atoms/Lottie";
import Tooltip from "@material-ui/core/Tooltip";

interface MediaCardProps {
  id?: string;
  title?: any;
  description?: any;
  imgSrc?: any;
  lottieSource?: any;
  lottiePath?: any;
  lottieSpeed?: number;
  loop?: boolean;
  bounce?: boolean;
  hoverPlay?: boolean;
  autoplay?: boolean;
  clickLink?: string;
}

export const MediaCard = (props: MediaCardProps) => {
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={(e: any) => {
        e.preventDefault();
        props.clickLink && window.open(props.clickLink, "_blank");
      }}
    >
      <Atom.Card style={{ width: "20rem", padding: "1rem" }}>
        {props.imgSrc && (
          <div
            style={{
              height: 140,
              width: "100%",
              backgroundImage: `url("${props.imgSrc}")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        )}
        {props.lottieSource && (
          <Lottie
            id={props.id}
            style={{ height: 140 }}
            source={props.lottieSource}
            loop={props.loop}
            bounce={props.bounce}
            hoverPlay={props.hoverPlay}
            autoplay={props.autoplay}
          />
        )}
        {props.lottiePath && (
          <Lottie
            id={props.id}
            style={{ height: 140 }}
            path={props.lottiePath}
            loop={props.loop}
            bounce={props.bounce}
            hoverPlay={props.hoverPlay}
            autoplay={props.autoplay}
            speed={props.lottieSpeed || 1}
          />
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Tooltip title={props.description}>
            <Typography variant="body2" component="p" noWrap>
              {props.description}
            </Typography>
          </Tooltip>
        </CardContent>
      </Atom.Card>
    </div>
  );
};
