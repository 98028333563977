import React from "react";
import * as Mui from "@material-ui/core";
import { DARK_OPACITY, BLUR } from "./options";
import * as Atom from "./Mui";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

interface DiscoverCardProps {
  avatar?: Omit<Atom.AvatarProps, "size">;
  title?: string;
  subtitle?: string;
  certified?: boolean;
  bgUrl?: string;
  button?: string;
  footerText?: string;
  footerJSX?: JSX.Element;
  onClick: any;
}

const discoverCardStyles = makeStyles((theme) => ({
  root: {
    height: "200px",
    opacity: 0.1,
  },
  header: {
    color: "white",
    backgroundColor: `rgba(0, 0, 0, 0.5)`,
    backdropFilter: `blur(${BLUR}px)`,
    "& .MuiListItemText-secondary": {
      color: "white",
    },
  },
  avatar: {
    height: 50,
    width: 50,
  },
}));

export const DiscoveryCard = (props: DiscoverCardProps) => {
  const classes = discoverCardStyles();
  return (
    <div className={clsx(classes.root)}>
      <Mui.ListItem className={classes.header}>
        {props.avatar && (
          <Mui.ListItemAvatar>
            <Atom.Avatar size="medium" {...props.avatar} />
          </Mui.ListItemAvatar>
        )}
        <Mui.ListItemText primary={props.title} secondary={props.subtitle} />
        {props.certified ? (
          <Mui.IconButton edge="end" aria-label="certified">
            {/* <VerifiedUserIcon style={{ color: "white" }} /> */}
          </Mui.IconButton>
        ) : (
          <div />
        )}
      </Mui.ListItem>
      <div className="flex-grow-1"></div>
      <div className="d-flex justify-content-end p-2">
        {/* <Atom.Button size="large" color="primary"  label={props.button} /> */}
      </div>
      <div className={clsx(classes.header, "p-2")}>
        <Atom.T variant="subtitle1" text={props.footerText} />
        <Mui.Typography variant="subtitle1">{props.footerJSX}</Mui.Typography>
      </div>
      <Atom.ButtonBase
        className="position-absolute w-100 h-100"
        style={{ top: 0, left: 0 }}
        onClick={props.onClick}
      />
    </div>
  );
};
