import React from "react";
import Atom from "../atoms/Atoms";
import Boxes from "../atoms/Boxes/Boxes";
import { Lottie } from "../atoms/Lottie";
import background from "../animations/background.json";
import { GoBack } from "../atoms/components/GoBack";

interface Props {
  backgroundUrl?: string;
}

class Skills extends React.Component<Props> {
  render() {
    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <Boxes />
        <Atom.Container style={{ padding: "5px" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={(e: any) => {
              e.preventDefault();
              window.location.href = "/";
            }}
          >
            <Atom.Card transparent>
              <h1>There are some of my skills..</h1>
            </Atom.Card>
          </div>
        </Atom.Container>
        <GoBack />
      </div>
    );
  }
}

export default Skills;
