import React from "react";
import { Lottie } from "../atoms/Lottie";
import gradient from "../animations/gradient.json";
import background from "../animations/background.json";

interface Props {
  backgroundUrl?: string;
}

const Background = (props: Props) => {
  return (
    <Lottie
      autoplay
      loop
      id={"back"}
      path={props.backgroundUrl}
      source={props.backgroundUrl ? undefined : background}
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
      }}
      aspect="none"
    />
  );
};

export default Background;
