import React from "react";

export const GoBack = () => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: 15,
        left: 15,
        cursor: "pointer",
      }}
      onClick={(e: any) => {
        e.preventDefault();
        window.location.href = "/";
      }}
    >
      <img
        src="/goBack.png"
        height={50}
        style={{ transform: "rotate(0deg)" }}
      />
    </div>
  );
};
